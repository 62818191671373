<template>
    <div class="year">
        <div v-if="!loaded" class="year__loading">
            <h3>Loading...</h3>
        </div>
        <div v-if="loaded" class="year__content">
            <h1>{{yr}}</h1>
            <div class="year__nav">
                <button v-if="yr > 2003" @click="previousYear" class="year__navLeft">&lt; Previous Year</button>
                <button v-if="yr < maxYear" @click="nextYear">Next Year &gt;</button>
            </div>
            <h3 class="year__subheading">Bracket</h3>
            <bracket :brkt="brkt" :places="places"/>
            <h3 class="year__subheading">Players</h3>
            <div class="year__tableContainer">
                <b-table 
                    class="year__table"
                    striped
                    :items="players"
                    :fields="playerFields"
                    label-sort-asc=""
                    label-sort-desc=""
                    label-sort-clear=""
                    sort-by="finalPlace"
                    selectable
                    @row-selected="goToPlayer"
                >
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import Bracket from "../components/Bracket.vue"

export default {
    components: {
        "bracket": Bracket
    },
    data: () => {return {
        yr: null,
        players: [],
        brkt: [],
        places: [],
        loaded: false,
        maxYear: null,
        playerFields: [
            {
                key: "first_name",
                label: "First Name",
                sortable: true
            },
            {
                key: "last_name",
                label: "Last Name",
                sortable: true
            },
            {
                key: "seed",
                label: "Seed",
                sortable: true
            },
            {
                key: "match1",
                label: "M1",
                sortable: false
            },
            {
                key: "match2",
                label: "M2",
                sortable: false
            },
            {
                key: "match3",
                label: "M3",
                sortable: false
            },
            {
                key: "match4",
                label: "M4",
                sortable: false
            },
            {
                key: "finalPlace",
                label: "Final Place",
                sortable: true,
                variant: 'success'
            }
        ]
    }},
    beforeMount: function() {
        this.loadData()
    },
    methods: {
        loadData: async function() {
            // Get the maximum year
            const yrsReq = await fetch(`${process.env.VUE_APP_API_LOCATION}/year`)
            const yrs = await yrsReq.json()
            this.maxYear = Math.max(...yrs)

            this.yr = this.$route.params.yr
            const yrDataReq = await fetch(`${process.env.VUE_APP_API_LOCATION}/year/${this.yr}`);
            const yrData = await yrDataReq.json()

            this.players = yrData.players;
            for (const p of this.players) {
                p.match1 = "W";
                p.match2 = "W";
                p.match3 = "W";
                p.match4 = "W";
            }
            
            // Allocate space for places
            this.places = Array(16);
            for (let i = 0; i < 16; i++) {
                this.places[i] = {
                    id: null,
                    name: 'Bye'
                }
            }

            // Load the players into their proper places
            for (const pl of this.players) {
                this.places[pl.finalPlace - 1] = {
                    id: pl.id,
                    name: `${pl.first_name} ${pl.last_name}`
                }
            }

            // Allocate space for the bracket
            this.brkt = Array(32);
            // Initialize all matches to have double byes
            for (let i = 0; i < 32; i++) {
                this.brkt[i] = {
                    p1: {
                        id: null,
                        name: "Bye"
                    },
                    p2: {
                        id: null,
                        name: "Bye"
                    }
                }
            }

            // Loop through matches
            for (const match of yrData.matches) {
                if (match.players.length < 2) {
                    // Check if this is a starting match
                    if (match.location < 8) {
                        // Second player will always be the bye
                        const p1 = this.players.find(elem => elem.id === match.players[0])
                        this.brkt[match.location] = {
                            p1: {
                                id: p1.id,
                                name: `${p1.first_name} ${p1.last_name}`
                            },
                            p2: {
                                id: null,
                                name: "Bye"
                            }
                        }
                        // Skip other processing
                        continue
                    }
                    // Find the previous matches
                    const prevMatches = this.getPreviousMatches(match.location);
                    // Get the previous matches
                    const firstPrevMatch = yrData.matches.find(elem => elem.location === prevMatches.locations[0]);
                    // If the first match is the one with the bye
                    if (firstPrevMatch.players.length < 2) {
                        // The second player is the non bye so find it
                        const p2 = this.players.find(elem => elem.id === match.players[0])
                        // Populate accordingly
                        this.brkt[match.location] = {
                            p1: {
                                id: null,
                                name: "Bye"
                            },
                            p2: {
                                id: p2.id,
                                name: `${p2.first_name} ${p2.last_name}`
                            }
                        }
                    } else {
                        // First player is the non bye
                        const p1 = this.players.find(elem => elem.id === match.players[0])
                        this.brkt[match.location] = {
                            p1: {
                                id: p1.id,
                                name: `${p1.first_name} ${p1.last_name}`
                            },
                            p2: {
                                id: null,
                                name: "Bye"
                            }
                        }
                    }
                    // Stop processing
                    continue
                }

                // Get the players in the match
                const p1 = this.players.find(elem => elem.id === match.players[0]);
                const p2 = this.players.find(elem => elem.id === match.players[1]);

                // Format it correctly for the bracket
                this.brkt[match.location] = {
                    p1: {
                        id: p1.id,
                        name: `${p1.first_name} ${p1.last_name}`
                    },
                    p2: {
                        id: p2.id,
                        name: `${p2.first_name} ${p2.last_name}`
                    }
                }

                // Get the loser
                const loser = (match.won === match.players[0]) ? p2 : p1;
                // Set the loser to have lost this match
                loser[`match${((match.location >> 3) & 0b00011) + 1}`] = "L"
            }
            
            // Successfully loaded information
            this.loaded = true;
        },
        // Gets the previous match locations based on a match location
        getPreviousMatches(matchLoc) {
            const rnd = matchLoc >> 3;
            // Determine if this is a winners or losers match by checking if (round - 1)th bit set
            const winners = ((matchLoc & (1 << (rnd - 1))) > 0)
            // Determine the previous matches
            const locsToReturn = [
                ((rnd - 1) << 3) | ((matchLoc & 0b00111) & (~(1 << (rnd - 1)))),
                ((rnd - 1) << 3) | ((matchLoc & 0b00111) | (1 << (rnd - 1)))
            ];
            return {
                winners: winners,
                locations: locsToReturn
            }
        },
        goToPlayer: function(selectedRow) {
            this.$router.push({ path: `/player/${selectedRow[0].id}`})
        },
        previousYear: function() {
            this.$router.push({ path: `/year/${parseInt(this.yr) - 1}`})
            location.reload()
        },
        nextYear: function() {
            this.$router.push({ path: `/year/${parseInt(this.yr) + 1}`})
            location.reload()
        }
    }
}
</script>

<style lang="scss">

.year {
    padding-bottom: 100px;

    &__nav {
        display: flex;

        &Left {
            margin-right: 20px;
        }
    }

    &__tableContainer {
        max-width: 100%;
        overflow-x: auto
    }

    &__subheading {
        margin-top: 20px;
    }
}

</style>