<template>
    <div class="bracket">
        <div id="bracket__container" class="bracket__container">
            <table class="bracket__table">
                <tr>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[0].p1.id !== null) ? `/player/${brkt[0].p1.id}` : ''">
                        (1) {{brkt[0].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[4].p1.id !== null) ? `/player/${brkt[4].p1.id}` : ''">
                            {{brkt[4].p1.name}} (2)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[9].p1.id !== null) ? `/player/${brkt[9].p1.id}` : ''">
                            {{brkt[9].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[13].p1.id !== null) ? `/player/${brkt[13].p1.id}` : ''">
                            {{brkt[13].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__left"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__left"/>
                    <td class="bracket__left"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[0].p2.id !== null) ? `/player/${brkt[0].p2.id}` : ''">
                            (16) {{brkt[0].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[4].p2.id !== null) ? `/player/${brkt[4].p2.id}` : ''">
                            {{brkt[4].p2.name}} (15)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[8].p1.id !== null) ? `/player/${brkt[8].p1.id}` : ''">
                            {{brkt[8].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[16].p1.id !== null) ? `/player/${brkt[16].p1.id}` : ''">
                            {{brkt[16].p1.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[19].p1.id !== null) ? `/player/${brkt[19].p1.id}` : ''">
                            {{brkt[19].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[23].p1.id !== null) ? `/player/${brkt[23].p1.id}` : ''">
                            {{brkt[23].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[18].p1.id !== null) ? `/player/${brkt[18].p1.id}` : ''">
                            {{brkt[18].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[28].p1.id !== null) ? `/player/${brkt[28].p1.id}` : ''">
                            {{brkt[28].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[1].p1.id !== null) ? `/player/${brkt[1].p1.id}` : ''">
                            (8) {{brkt[1].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[5].p1.id !== null) ? `/player/${brkt[5].p1.id}` : ''">
                            {{brkt[5].p1.name}} (7)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[8].p2.id !== null) ? `/player/${brkt[8].p2.id}` : ''">
                            {{brkt[8].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[16].p2.id !== null) ? `/player/${brkt[16].p2.id}` : ''">
                            {{brkt[16].p2.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[30].p1.id !== null) ? `/player/${brkt[30].p1.id}` : ''">
                            {{brkt[30].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(places[12].id !== null) ? `/player/${places[12].id}` : ''">
                            {{places[12].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[9].p2.id !== null) ? `/player/${brkt[9].p2.id}` : ''">
                            {{brkt[9].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[13].p2.id !== null) ? `/player/${brkt[13].p2.id}` : ''">
                            {{brkt[13].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[10].p1.id !== null) ? `/player/${brkt[10].p1.id}` : ''">
                            {{brkt[10].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__label bracket__right">
                        13th Place
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[20].p1.id !== null) ? `/player/${brkt[20].p1.id}` : ''">
                            {{brkt[20].p1.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[18].p2.id !== null) ? `/player/${brkt[18].p2.id}` : ''">
                            {{brkt[18].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[28].p2.id !== null) ? `/player/${brkt[28].p2.id}` : ''">
                            {{brkt[28].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[1].p2.id !== null) ? `/player/${brkt[1].p2.id}` : ''">
                            (9) {{brkt[1].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[5].p2.id !== null) ? `/player/${brkt[5].p2.id}` : ''">
                            {{brkt[5].p2.name}} (10)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[10].p2.id !== null) ? `/player/${brkt[10].p2.id}` : ''">
                            {{brkt[10].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[20].p2.id !== null) ? `/player/${brkt[20].p2.id}` : ''">
                            {{brkt[20].p2.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[31].p1.id !== null) ? `/player/${brkt[31].p1.id}` : ''">
                            {{brkt[31].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[31].p2.id !== null) ? `/player/${brkt[31].p2.id}` : ''">
                            {{brkt[31].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[2].p1.id !== null) ? `/player/${brkt[2].p1.id}` : ''">
                            (4) {{brkt[2].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[6].p1.id !== null) ? `/player/${brkt[6].p1.id}` : ''">
                            {{brkt[6].p1.name}} (3)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(places[8].id !== null) ? `/player/${places[8].id}` : ''">
                            {{places[8].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[12].p1.id !== null) ? `/player/${brkt[12].p1.id}` : ''">
                            {{brkt[12].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__label">
                        9th Place
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[17].p1.id !== null) ? `/player/${brkt[17].p1.id}` : ''">
                            {{brkt[17].p1.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[11].p1.id !== null) ? `/player/${brkt[11].p1.id}` : ''">
                            {{brkt[11].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(places[0].id !== null) ? `/player/${places[0].id}` : ''">
                            {{places[0].name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[15].p1.id !== null) ? `/player/${brkt[15].p1.id}` : ''">
                            {{brkt[15].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[22].p1.id !== null) ? `/player/${brkt[22].p1.id}` : ''">
                            {{brkt[22].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[29].p1.id !== null) ? `/player/${brkt[29].p1.id}` : ''">
                            {{brkt[29].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__label">
                        Champion
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[12].p2.id !== null) ? `/player/${brkt[12].p2.id}` : ''">
                            {{brkt[12].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[17].p2.id !== null) ? `/player/${brkt[17].p2.id}` : ''">
                            {{brkt[17].p2.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[2].p2.id !== null) ? `/player/${brkt[2].p2.id}` : ''">
                            (13) {{brkt[2].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[6].p2.id !== null) ? `/player/${brkt[6].p2.id}` : ''">
                            {{brkt[6].p2.name}} (14)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[30].p2.id !== null) ? `/player/${brkt[30].p2.id}` : ''">
                            {{brkt[30].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(places[4].id !== null) ? `/player/${places[4].id}` : ''">
                            {{places[4].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[19].p2.id !== null) ? `/player/${brkt[19].p2.id}` : ''">
                            {{brkt[19].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[23].p2.id !== null) ? `/player/${brkt[23].p2.id}` : ''">
                            {{brkt[23].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[14].p1.id !== null) ? `/player/${brkt[14].p1.id}` : ''">
                            {{brkt[14].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__label bracket__right">
                        5th Place
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[21].p1.id !== null) ? `/player/${brkt[21].p1.id}` : ''">
                            {{brkt[21].p1.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[3].p1.id !== null) ? `/player/${brkt[3].p1.id}` : ''">
                            (5) {{brkt[3].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[7].p1.id !== null) ? `/player/${brkt[7].p1.id}` : ''">
                            {{brkt[7].p1.name}} (6)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[22].p2.id !== null) ? `/player/${brkt[22].p2.id}` : ''">
                            {{brkt[22].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[29].p2.id !== null) ? `/player/${brkt[29].p2.id}` : ''">
                            {{brkt[29].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[14].p2.id !== null) ? `/player/${brkt[14].p2.id}` : ''">
                            {{brkt[14].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[21].p2.id !== null) ? `/player/${brkt[21].p2.id}` : ''">
                            {{brkt[21].p2.name}}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[11].p2.id !== null) ? `/player/${brkt[11].p2.id}` : ''">
                            {{brkt[11].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[15].p2.id !== null) ? `/player/${brkt[15].p2.id}` : ''">
                            {{brkt[15].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[3].p2.id !== null) ? `/player/${brkt[3].p2.id}` : ''">
                            (12) {{brkt[3].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[7].p2.id !== null) ? `/player/${brkt[7].p2.id}` : ''">
                            {{brkt[7].p2.name}} (11)
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[29].p1.id !== null) ? `/player/${brkt[26].p1.id}` : ''">
                            {{brkt[26].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[27].p1.id !== null) ? `/player/${brkt[27].p1.id}` : ''">
                            {{brkt[27].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(places[10].id !== null) ? `/player/${places[10].id}` : ''">
                            {{places[10].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(places[2].id !== null) ? `/player/${places[2].id}` : ''">
                            {{places[2].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[26].p2.id !== null) ? `/player/${brkt[26].p2.id}` : ''">
                            {{brkt[26].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__label">
                        11th Place
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__label  bracket__right">
                        3rd Place
                    </td>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[27].p2.id !== null) ? `/player/${brkt[27].p2.id}` : ''">
                            {{brkt[27].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[24].p1.id !== null) ? `/player/${brkt[24].p1.id}` : ''">
                            {{brkt[24].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[25].p1.id !== null) ? `/player/${brkt[25].p1.id}` : ''">
                            {{brkt[25].p1.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__right"/>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(places[14].id !== null) ? `/player/${places[14].id}` : ''">
                            {{places[14].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(places[6].id !== null) ? `/player/${places[6].id}` : ''">
                            {{places[6].name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
                <tr>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                    <td class="bracket__bottom bracket__right">
                        <router-link class="bracket__link" :to="(brkt[24].p2.id !== null) ? `/player/${brkt[24].p2.id}` : ''">
                            {{brkt[24].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__label">
                        15th Place
                    </td>
                    <td class="bracket__blank"/>
                    <td class="bracket__label bracket__right">
                        7th Place
                    </td>
                    <td class="bracket__bottom">
                        <router-link class="bracket__link" :to="(brkt[25].p2.id !== null) ? `/player/${brkt[25].p2.id}` : ''">
                            {{brkt[25].p2.name}}
                        </router-link>
                    </td>
                    <td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/><td class="bracket__blank"/>
                </tr>
            </table>
        </div>
        <div class="bracket__zoom">
            <button class="bracket__zBtn" @click="zoomIn">+</button><br>
            <button class="bracket__zBtn" @click="zoomOut">-</button><br>
            <button class="bracket__zBtn" @click="zoomReset">0</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "bracket",
    data: () => {return {
        zoomPercent: 1.0,
        zoomRuleIndex: null
    }},
    methods: {
        zoomIn: function() {
            this.zoomPercent += 0.1
            const css = window.document.styleSheets[0];
            if (this.zoomRuleIndex !== null) {
                css.deleteRule(this.zoomRuleIndex)
            }
            this.zoomRuleIndex = css.insertRule(`.bracket__table { transform: scale(${this.zoomPercent}); }`)
            document.getElementById("bracket__container").scrollTo(0, 0);
        },
        zoomOut: function() {
            this.zoomPercent -= 0.1
            const css = window.document.styleSheets[0];
            if (this.zoomRuleIndex !== null) {
                css.deleteRule(this.zoomRuleIndex)
            }
            this.zoomRuleIndex = css.insertRule(`.bracket__table { transform: scale(${this.zoomPercent}); }`)
            document.getElementById("bracket__container").scrollTo(0, 0);
        },
        zoomReset: function() {
            this.zoomPercent = 1.0;
            const css = window.document.styleSheets[0];
            if (this.zoomRuleIndex !== null) {
                css.deleteRule(this.zoomRuleIndex)
            }
            this.zoomRuleIndex = css.insertRule(`.bracket__table { transform: scale(${this.zoomPercent}); }`)
            document.getElementById("bracket__container").scrollTo(0, 0);
        }
    },
    props: ["brkt", "places"]
}
</script>

<style lang="scss">

$column-width: 150px;

.bracket {
    display: flex;

    &__container {
        overflow: auto;
        width: 100%;
        height: 500px;
    }

    &__table {
        margin: 10px;
        width: calc($column-width * 18);
        transform-origin: 0% 0% 0px;
    }

    &__bottom {
        border-bottom: 1px solid black;
        padding-left: 5px;
        padding-right: 5px;
        height: 1rem;
        width: $column-width;
    }

    &__right {
        border-right: 1px solid black;
        height: 1rem;
        width: $column-width;
    }
    
    &__left {
        border-left: 1px solid black;
        height: 1rem;
        width: $column-width;
    }

    &__blank {
        height: 1rem;
        width: $column-width;
    }

    &__label {
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 600;
    }
    
    &__link {
        color: black;
        text-decoration: none;
    }

    &__zBtn {
        background: #fff;
        width: 30px;
        margin-bottom: 10px;
        font-size: 1.2rem;
    }
}

</style>