<template>
    <div class="player">
        <div v-if="loading" class="player__loading">
            <h4>Loading...</h4>
        </div>
        <div v-if="!loading" class="player__overview">
            <!-- <img class="player__pic" /> -->
            <h1 class="player__name"> {{first_name}} {{last_name}} </h1>
            <p class="player__record">
                <span class="player__record--wins">
                    {{wins}}
                </span>
                - 
                <span class="player__record--losses">
                    {{losses}}
                </span>
                ({{percentage}})
            </p>
            <!--
            <p class="player__bio">
                <strong>Bio:</strong> {{bio}}
            </p>
            -->
        </div>
        <div v-if="!loading" class="player__yearSection">
            <h3>By Year</h3>
            <div class="player__yearSelection">
                <select @change="updateYear" v-model="selectedYear" class="player__yearSelect">
                    <option :key="year" v-for="year in years">
                        {{year}}
                    </option>
                </select>
                <button @click="toYear" class="player__yearButton">
                    Visit Year Page
                </button>
            </div>
            <div class="player__yearData">
                <p class="player__seedHandicap">Seed: {{yearData.seed}}</p>
                <p class="player__match" v-if="'match1' in yearData">
                    Match 1 against 
                    <a class="player__match--opponent" @click="() => {toPlayer(yearData.match1.against.id)}">
                        {{yearData.match1.against.name}}
                    </a>:
                    <span class="player__match--won" v-if="yearData.match1.won">
                        Won
                    </span>
                    <span class="player__match--lost" v-if="!yearData.match1.won">
                        Lost
                    </span>
                </p>
                <p class="player__match" v-if="'match2' in yearData">
                    Match 2 against 
                    <a class="player__match--opponent" @click="() => {toPlayer(yearData.match2.against.id)}">
                        {{yearData.match2.against.name}}
                    </a>:
                    <span class="player__match--won" v-if="yearData.match2.won">
                        Won
                    </span>
                    <span class="player__match--lost" v-if="!yearData.match2.won">
                        Lost
                    </span>
                </p>
                <p class="player__match" v-if="'match3' in yearData">
                    Match 3 against 
                    <a class="player__match--opponent" @click="() => {toPlayer(yearData.match3.against.id)}">
                        {{yearData.match3.against.name}}
                    </a>:
                    <span class="player__match--won" v-if="yearData.match3.won">
                        Won
                    </span>
                    <span class="player__match--lost" v-if="!yearData.match3.won">
                        Lost
                    </span>
                </p>
                <p class="player__match" v-if="'match4' in yearData">
                    Match 4 against 
                    <a class="player__match--opponent" @click="() => {toPlayer(yearData.match4.against.id)}">
                        {{yearData.match4.against.name}}
                    </a>:
                    <span class="player__match--won" v-if="yearData.match4.won">
                        Won
                    </span>
                    <span class="player__match--lost" v-if="!yearData.match4.won">
                        Lost
                    </span>
                </p>
                <p class="player__finalPlace">
                    Final Place: {{yearData.finalPlace}}
                </p>
            </div>
        </div>
        <div v-if="!loading" class="player__opponentSection">
            <h3>By Opponent</h3>
            <div class="player__opponentSelection">
                <select @change="updateOpponent" v-model="selectedOpponent" class="player__opponentSelect">
                    <option :key="opponent.id" v-for="opponent in opponents">
                        {{opponent.name}}
                    </option>
                </select>
                <button @click="toOpponent" class="player__opponentButton">
                    Visit Opponent Page
                </button>
            </div>
            <div class="player__opponentData">
                <div v-if="opponentData.record.length > 0" class="player__opponentRecords">
                    Record: <span class="player__record--wins">{{opponentData.wins}}</span> - <span class="player__record--losses">{{opponentData.losses}}</span> ({{opponentData.percent}})
                    <div v-for="rec in opponentData.record" :key="`${rec.year}-${rec.match}`" class="player__opponentRecord">
                        {{rec.year}}, Match {{rec.match}}: 
                        <span class="player__match--won" v-if="rec.won">
                            Won
                        </span>
                        <span class="player__match--lost" v-if="!rec.won">
                            Lost
                        </span>
                    </div>
                </div>
                <div class="player__opponentOdds">
                    Calculated Odds:<br>
                    <div class="player__oddsBar">
                        <div :style="`width: ${opponentData.winOdds}%;`" class="player__oddsBar--left">
                            {{opponentData.winOdds}}%<br>Win
                        </div>
                        <div :style="`width: ${opponentData.loseOdds}%;`" class="player__oddsBar--right">
                            {{opponentData.loseOdds}}%<br>Lose
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Player",
    methods: {
        loadData: async function() {
            // Request the player information
            let playerReq = await fetch(`${process.env.VUE_APP_API_LOCATION}/player/${ this.$route.params.id }`)
            let playerData = await playerReq.json();

            // Request all player information
            let playersReq = await fetch(`${process.env.VUE_APP_API_LOCATION}/player`)
            this.playersData = await playersReq.json()

            this.id = playerData.id;
            this.first_name = playerData.first_name;
            this.last_name = playerData.last_name;
            this.wins = playerData.wins;
            this.losses = playerData.losses;
            this.percentage = ((this.wins / (this.wins + this.losses)) * 100).toFixed(2) + "%";
            this.bio = playerData.bio;
            this.elo = playerData.elo;

            this.years = [];
            this.opponentsData = {};
            this.yearData = {};
            this.yearsData = {};
            
            for (const yr of playerData.years) {
                // Add the year to list of years
                this.years.push(yr.year);

                // Update year data information
                this.yearsData[yr.year.toString()] = {
                    seed: yr.seed,
                    handicap: yr.handicap,
                    finalPlace: yr.finalPlace,
                }

                // Loop through matches
                for (const mat of yr.matches) {
                    if (mat.against === null) {
                        // TODO: Handle byes here
                        continue;
                    }
                    // Get the opponent
                    const opp = this.playersData.find(obj => {
                        return obj.id === mat.against
                    })
                    // Create a consistent opponent name used for indexing
                    const oppName = `${opp.first_name} ${opp.last_name}`

                    // Calculate which match this was
                    const matchNum = ((mat.location >> 3) & 0b00011) + 1;

                    this.yearsData[yr.year.toString()][`match${matchNum}`] = {
                        against: {
                            name: oppName,
                            id: mat.against
                        },
                        won: mat.won
                    }

                    // Allocate space for the opponent in the data object
                    if (!(oppName in this.opponentsData)) {
                        this.opponentsData[oppName] = {
                            wins: 0,
                            losses: 0,
                            percent: null,
                            record: [],
                            id: opp.id,
                            elo: opp.elo
                        }
                    }

                    // Check if they won the match and update accordingly
                    if (mat.won) {
                        this.opponentsData[oppName].wins += 1
                    } else {
                        this.opponentsData[oppName].losses += 1
                    }

                    // Update percentages and record
                    const w = this.opponentsData[oppName].wins
                    const l = this.opponentsData[oppName].losses
                    this.opponentsData[oppName].percent = `${(w / (w + l) * 100).toFixed(2)}%`
                    this.opponentsData[oppName].record.push({
                        year: yr.year,
                        match: matchNum,
                        won: mat.won
                    })
                }
            }

            // Add opponents
            this.opponents = []
            this.playersData.forEach(p => {
                if (p.id !== this.id) {
                    const oppName = `${p.first_name} ${p.last_name}`
                    if (!(oppName in this.opponentsData)) {
                        this.opponentsData[oppName] = {
                            id: p.id,
                            name: `${p.first_name} ${p.last_name}`,
                            wins: 0,
                            losses: 0,
                            percent: null,
                            record: [],
                            elo: p.elo
                        }
                    }
                    this.opponents.push({
                        id: p.id,
                        name: oppName,
                        last_name: p.last_name,
                        first_name: p.first_name
                    })
                }
            })

            // Alphabetize opponents
            this.opponents.sort((p1, p2) => {
                if (p1.last_name < p2.last_name) {
                    return -1;
                } else if (p1.last_name > p2.last_name) {
                    return 1;
                } else {
                    if (p1.first_name < p2.first_name) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            })

            this.selectedYear = this.years[0];
            this.yearData = this.yearsData[this.selectedYear]

            this.selectedOpponent = this.opponents[0].name
            this.opponentData = this.opponentsData[this.selectedOpponent]
            // Calculate elo scores
            const aOdds = this.calculateEloOdds(this.elo, this.opponentData.elo) * 100
            const bOdds = 100 - aOdds;
            this.opponentData.winOdds = aOdds.toFixed(2)
            this.opponentData.loseOdds = bOdds.toFixed(2)

            // Set loading to false
            this.loading = false;
        },
        updateYear: function() {
            this.yearData = this.yearsData[this.selectedYear]
        },
        updateOpponent: function() {
            this.opponentData = this.opponentsData[this.selectedOpponent]
            // Calculate elo odds
            const aOdds = this.calculateEloOdds(this.elo, this.opponentData.elo) * 100
            const bOdds = 100 - aOdds;
            this.opponentData.winOdds = aOdds.toFixed(2)
            this.opponentData.loseOdds = bOdds.toFixed(2)

        },
        toYear: function() {
            this.$router.push(`/year/${this.selectedYear}`)
        },
        toOpponent: function() {
            this.toPlayer(this.opponentData.id)
        },
        toPlayer: function(playerId) {
            this.$router.push(`/player/${playerId}`)
            location.reload()
        },
        calculateEloOdds: function(eloA, eloB) {
            return (1 / (
                1 + Math.pow(10, (eloB - eloA) / 400)
            ))
        }
    },
    beforeMount: function() {
        this.loadData()
    },
    data: () => {return {
        loading: true,
        id: null,
        first_name: null,
        last_name: null,
        elo: null,
        wins: null,
        losses: null,
        percentage: null,
        bio: null,
        photo: null,
        years: null,
        selectedYear: null,
        yearData: null,
        opponents: null,
        selectedOpponent: null,
        opponentData: null,
        opponentsData: {},
        yearsData: null,
        playersData: {}
    }}
}
</script>

<style lang="scss">

.player {
    padding-bottom: 100px;
    &__overview {
        contain: content;
    }

    &__pic {
        height: 100px;
        width: 100px;
        float: right;
    }

    &__record {
        &--wins {
            color: darkgreen;
        }

        &--losses {
            color: red;
        }
    }

    &__yearSelection {
        display: flex;
    }

    &__yearSelect {
        flex: 4;
        margin-right: 10px;
    }

    &__yearData {
        margin-top: 10px;
        border: 1px solid gray;
        padding: 10px;
    }

    &__match {
        &--opponent {
            color: black;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
        }
        &--won {
            color: darkgreen;
            margin-left: 5px;
        }
        &--lost {
            color: red;
            margin-left: 5px;
        }
    }

    &__finalPlace {
        font-weight: bold;
        font-size: 1.2rem;
    }

    &__opponentSection {
        margin-top: 20px;
    }

    &__opponentSelection {
        display: flex;
    }

    &__opponentSelect {
        flex: 4;
        margin-right: 10px;
    }

    &__opponentData {
        margin-top: 10px;
        border: 1px solid gray;
        padding: 10px;
    }

    &__opponentOdds{
        margin-top: 10px;
    }

    &__oddsBar {
        display: flex;
        border: 1px solid black;

        &--left {
            text-align: center;
            color: white;
            background: darkgreen;
        }

        &--right {
            text-align: center;
            color: white;
            background: red;
        }
    }
}

</style>