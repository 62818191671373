<template>
    <div class="home">
        <h3>Players</h3>
        <div class="home__pTableCont">
            <b-table 
                class="home__pTable"
                striped
                :busy="playerDataLoading"
                :items="playerData"
                :fields="playerFields"
                label-sort-asc=""
                label-sort-desc=""
                label-sort-clear=""
                sort-by="wins"
                sort-desc
                selectable
                @row-selected="goToPlayer"
            >
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>
        </div>
        <h3>Years</h3>
        <div class="home__years">
            <select v-model="selectedYear" class="home__yearsSelect">
                <option :key="year" v-for="year in years">
                    {{year}}
                </option>
            </select>
            <button 
                class="home__yearsButton"
                @click="goToYear"
            >
                Go
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data: () => {return {
        playerDataLoading: true,
        playerFields: [
            {
                key: "rank",
                label: "Rank",
                sortable: false
            },
            {
                key: "first_name",
                label: "First Name",
                sortable: true
            },
            {
                key: "last_name",
                label: "Last Name",
                sortable: true
            },
            {
                key: "elo",
                label: "Elo",
                sortable: true
            },
            {
                key: "wins",
                label: "Wins",
                sortable: true
            },
            {
                key: "losses",
                label: "Losses",
                sortable: true
            },
            {
                key: "percent",
                label: "Percent",
                sortable: true
            }
        ],
        playerData: [],
        years: [],
        selectedYear: null
    }},
    mounted: function() {
        this.loadPlayerData()
        this.loadYearData()
    },
    methods: {
        loadPlayerData: async function() {
            let playerReq = await fetch(`${process.env.VUE_APP_API_LOCATION}/player`)
            this.playerData = await playerReq.json()
            this.processPlayerData()
            this.playerDataLoading = false;
        },
        processPlayerData: function() {
            // Sort the players
            this.playerData.sort((a, b) => {
                if (a.wins > b.wins) {
                    return -1
                } else if (a.wins < b.wins) {
                    return 1
                } else {
                    return 0
                }
            })
            // Attach the extra datapoints
            for (let i = 0; i < this.playerData.length; i++) {
                this.playerData[i].rank = (i + 1)
                this.playerData[i].elo = Math.round(this.playerData[i].elo);
                this.playerData[i].percent = (
                    (
                        (this.playerData[i].wins / (this.playerData[i].wins + this.playerData[i].losses))* 100
                    ).toFixed(2) + "%"
                )
            }
        },
        loadYearData: async function() {
            let yearReq = await fetch(`${process.env.VUE_APP_API_LOCATION}/year`)
            this.years = await yearReq.json()
            this.selectedYear = this.years[0]
        },
        goToYear: function() {
            this.$router.push({ path: `/year/${this.selectedYear}`})
        },
        goToPlayer: function(selectedRow) {
            this.$router.push({ path: `/player/${selectedRow[0].id}`})
        }
    }
}
</script>

<style lang="scss">
.home {
    padding-bottom: 100px;
    &__pTableCont {
        max-width: 100%;
        overflow-x: auto
    }
    &__years {
        width: 100%;
        display: flex;
        align-items: center;

        &Select {
            flex: 6;
            margin-right: 10px;
            font-size: 1.2rem;
        }

        &Button {
            flex: 1;
        }
    }
}
</style>