import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Player from "../views/Player.vue"
import Year from "../views/Year.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/player/:id",
    name: "Player",
    component: Player
  },
  {
    path: "/year/:yr",
    name: "Year",
    component: Year
  }
]

const router = new VueRouter({
  routes
})

export default router
